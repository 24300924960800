//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoadingSkeleton from '@/components/LoadingSkeleton'
import ProfileCoverPhoto from '@/components/ProfileCoverPhoto'
import ProfileNamestrip from '@/components/Profile/ProfileNamestrip'
import BackButton from '@/components/BackButton'
export default {
    data() {
        return {
            profile: null,
            isLoading: true,
            tab: 'name'
        }
    },
    metaInfo(){
        return {
            title:'Settings'
        }
    },
    components: {
        Name: () => import('@/components/Profile/Settings/Name'),
        Course: () => import('@/components/Profile/Settings/Course'),
        Contact: () => import('@/components/Profile/Settings/Contact'),
        Security: () => import('@/components/Profile/Settings/Security'),
        UNHS: () => import('@/components/Profile/Settings/UNHS'),
        Work: () => import('@/components/Profile/Settings/Work'),
        DeleteAccount: () => import('@/components/Profile/Settings/DeleteAccount'),
        About: () => import('@/components/Profile/Settings/About'),
        LoadingSkeleton,
        ProfileCoverPhoto,
        ProfileNamestrip,
        BackButton
    },
    created() {
        this.load();
    },
    mounted(){
        this.$store.commit('hideLeftSidebar')
    },
    beforeDestroy(){
        this.$store.commit('displayLeftSidebar')
    },
    methods: {
        save(payload) {
          this.$q.loading.show({
            message: 'Saving..'
          });
          this.$http({
            url: 'profiles',
            method: 'PUT',
            data: payload
          }).then((response) => {
            this.$q.loading.hide();
              if (response.data['status'] == 'profile_updated') {
                localStorage.flash = 'Your profile has been successfully updated.';
                window.location.reload();
              }
              this.isLoading = false;
          });         
        },
        load() {
            if (!localStorage._uid) {
                this.kick();
            }
            this.$http({
                url: 'profiles/' + this.$store.state.user.id,
                method: 'GET'
            }).then((response) => {
              if (response.data['status'] == 'profile_retrieved') {
                this.profile = response.data['body']['profile'];
                this.profile.share_email_address = this.profile.share_email_address == 1 ? true : false;
                this.profile.share_mobile_number = this.profile.share_mobile_number == 1 ? true : false;
                this.profile.share_work_info = this.profile.share_work_info == 1 ? true : false;
                this.profile.share_hometown = this.profile.share_hometown == 1 ? true : false;

                if (localStorage.flash) {
                  this.$q.notify({
                    message: localStorage.flash,
                    color: 'positive'
                  });

                  localStorage.removeItem('flash');
                }
              } else {
                this.kick();
              }
            this.isLoading = false;
          });
        }  
    }
}
